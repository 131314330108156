import React, { useState, useEffect } from "react";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { useCookies } from "react-cookie";
import Select from 'react-select';

import { Query } from '@syncfusion/ej2-data';

import '../css/style.css';

import axios from 'axios'
import { Header } from "../components";
import GeoLocation from "./GeoLocation";
import { sexList, ageList, Rater } from '../data/list';
import isave from "../data/save-64-c.png";

const NewRating = () => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    })

    const [cookies] = useCookies()
    const dateValue = new Date();
    const disable = true;

    const office_fields = { text: 'name', value: 'id' };
    const rater_fields = { text: 'rater_name', value: 'id' };
    const sex_fields = { text: 'name', value: 'id' };
    const age_fields = { text: 'name', value: 'id' };
    
    const [officeList, setOfficeState] = useState([])
    const [issucess, setSuccess] = useState(false)
    const [servicesList, setServicesState] = useState([])

    const [selectedServicesValue, setSelectedServicesValue] = useState([]);

    const [visible, setVisible] = useState(false)

    const [dataEntry, setDataEntry] = useState( {
        rating_date: dateValue,
        rater: '',
        office: '',
        sex: '',
        age: '',
        service_avail: '',
        barangay_id: null,
        campus_id: cookies['__info'].campus_id,
        sqd0: '',
        sqd1: '',
        sqd2: '',
        sqd3: '',
        sqd4: '',
        sqd5: '',
        sqd6: '',
        sqd7: '',
        sqd8: '',
        suggestion: '',
        email_address: '',
        cc1: '',
        cc2: '',
        cc3: '',
        type_of_survey: 'onsite',
        language: 'English'
    })

    useEffect(() => {
        showOfficeList()
    }, [])

    useEffect(() => {
        dataEntry.service_avail = selectedServicesValue 
    }, [selectedServicesValue])
    
    const showOfficeList = () => {
        api.get('get/office/' + cookies['__info'].campus_id)
        .then(res => {
            if (!issucess) {
                setOfficeState(res.data)
                setSuccess(true)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    const showServicesList = (id) => {
        api.get('get/services/' + id)
        .then(res => {
            setServicesState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }


    const onFiltering = FilteringEventArgs => {
        let query = new Query();
        query = (FilteringEventArgs.text !== '') ? query.where('name', 'startswith', FilteringEventArgs.text, true) : query;
        FilteringEventArgs.updateData(officeList, query);
    }

    const onHandleChange = (e) => {
        const {name, value} = e.target

        setDataEntry({...dataEntry, [name]: value})
        
        if (name === 'office') {
            showServicesList(value)    
        }  
    }

    const onHandleChangeSelect2 = (e) => {
        setSelectedServicesValue(Array.isArray(e) ? e.map(x => x.value) : []);
    }

    const updateValueFromState = (val) => {
        setDataEntry({...dataEntry, barangay_id: val})
    }

    const onSubmitData = (e) => {
        e.preventDefault()

        if (dataEntry.sqd0.length === 0 || dataEntry.sqd1.length === 0 ||
            dataEntry.sqd2.length === 0 || dataEntry.sqd3.length === 0 ||
            dataEntry.sqd4.length === 0 || dataEntry.sqd5.length === 0 ||
            dataEntry.sqd6.length === 0 || dataEntry.sqd7.length === 0 ||
            dataEntry.sqd8.length === 0 || dataEntry.cc1.length === 0 ||
            dataEntry.cc2.length === 0 || dataEntry.cc3.length === 0 ||
            dataEntry.rater.length === 0 || dataEntry.office.length === 0 ||
            dataEntry.barangay_id.length === 0 || dataEntry.sex.length === 0 || 
            dataEntry.service_avail.length === 0 || dataEntry.age.length === 0) {
            setVisible(true)
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        } else {
            setVisible(false)
            saveNewRating(dataEntry)    
        }
    }

    const saveNewRating = async (params) => {
        let res = await api.post('post/save-rating', {params})

        if (res.status === 200) {
            if (res.data === 'success') {
                alert("New rating has been successfully added!")
                window.location.reload()
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            } else {
                alert("Attention! Failed to save, Please try again.")
            }
        }
    }

    return (
        <div className="m-2 m-4 p-2 md:p-10 bg-white rounded-3xl">
            <Header category="Rating" title="New Rating" />
            {
                visible ?
                <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <span className="sr-only">Info</span>
                    <div className="ml-2">
                        <span className="font-medium">Message alert!</span> All fields are required, Please check and try again.
                    </div>
                </div>
                : ''
            }
            

            <div className="flex gap-2 md:justify-end">
                <div className="flex flex-nowrap">
                    <TextBoxComponent placeholder="Ratee" name="ratee" id="ratee" cssClass="e-filled" floatLabelType="Auto" value="PILI" readOnly/>
                </div>
                <div className="flex flex-nowrap">
                    <TextBoxComponent placeholder="Controller#" style={{"width": "200px"}} name="controll_no" id="controll_no" cssClass="e-filled" floatLabelType="Auto" value="{SYSYEM GENERATED}" readOnly/>
                </div>
            </div>

            <div className="flex gap-12 mt-2 border-solid border-1 dark:border-neutral-500 px-3 py-1">
                <div className="flex-1">
                    <label className="text-gray-500 font-bold whitespace-nowrap text-sm" htmlFor="rating_date">Date:</label>
                    <DatePickerComponent strictMode={disable} format="dd/MM/yyyy" value={dateValue} max={dateValue} onChange={onHandleChange} name="rating_date" id="rating_date"></DatePickerComponent>
                </div>
                <div className="flex-1">
                    <label className="text-gray-500 font-bold whitespace-nowrap text-sm" htmlFor="rater">Rater:</label>
                    <DropDownListComponent name="rater" id="rater" onChange={onHandleChange} dataSource={Rater} fields={rater_fields} placeholder="Select a rater" />
                </div>
                <div className="flex-1">
                    <label className="text-gray-500 font-bold whitespace-nowrap text-sm" htmlFor="rater">Office:</label>
                    <DropDownListComponent name="office" id="office" onChange={onHandleChange} dataSource={officeList} filtering={onFiltering.bind(this)} filterBarPlaceholder='Search a office' allowFiltering={true} fields={office_fields} placeholder="Select a office" />   
                </div>
            </div>

            <GeoLocation updateValueFromState={updateValueFromState} />

            <div className="flex gap-12 mt-2 border-solid border-1 dark:border-neutral-500 px-3 py-1">
                <div className="flex-1">
                    <label className="text-gray-500 font-bold whitespace-nowrap text-sm" htmlFor="age">Age:</label>
                    <DropDownListComponent name="age" id="age" onChange={onHandleChange} dataSource={ageList} fields={age_fields} placeholder="Select a age" />
                </div>
                <div className="flex-1">
                    <label className="text-gray-500 font-bold whitespace-nowrap text-sm" htmlFor="sex">Sex:</label>
                    <DropDownListComponent name="sex" id="sex" onChange={onHandleChange} dataSource={sexList} fields={sex_fields} placeholder="Select a sex" />
                </div>
                <div className="flex-1">
                    <label className="text-gray-500 font-bold whitespace-nowrap text-sm" htmlFor="rater">Service Availed:</label>
                    <Select
                        isMulti
                        name="service_avail"
                        options={servicesList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select a service avail..."
                        onChange={onHandleChangeSelect2}
                    />
                </div>
            </div>

            <hr className="my-3 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />

            <div className="flex flex-col mt-2">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="table-fixed w-full text-left text-sm font-light">
                                <tbody>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-4 font-medium w-[80px]" rowSpan={5}><strong>CC1</strong></td>
                                        <td className="whitespace-nowrap px-6 py-4">
                                            <div className="flex font-bold">
                                                Which of the following best describes your awareness of a CC?
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-2">
                                            <div className="flex items-center">
                                                <input type="radio" onChange={onHandleChange} value="1" name="cc1" id="cc1_1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label htmlFor="cc1_1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 1. I know what a CC is and I saw this office’s CC.</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-2">
                                            <div className="flex items-center">
                                                <input type="radio" onChange={onHandleChange} value="2" name="cc1" id="cc1_2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label htmlFor="cc1_2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 2. I know what a CC is but I did NOT see this office’s CC.</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-2">
                                            <div className="flex items-center">
                                                <input type="radio" onChange={onHandleChange} value="3" name="cc1" id="cc1_3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label htmlFor="cc1_3" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 3. I learned of the CC only when I saw this office’s CC.</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-2">
                                            <div className="flex items-center">
                                                <input type="radio" onChange={onHandleChange} value="4" name="cc1" id="cc1_4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label htmlFor="cc1_4" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 4. I do not know what a CC is and I did not see one in this office. (Answer ‘N/A’ on CC2 and CC3)</label>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-4 font-medium w-[80px]" rowSpan={6}><strong>CC2</strong></td>
                                        <td className="whitespace-nowrap px-6 py-4">
                                            <div className="flex font-bold">
                                                If aware of CC (answered 1-3 in CC1), would you say that the CC of this office was …?
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-2">
                                            <div className="flex items-center">
                                                <input type="radio" onChange={onHandleChange} value="1" name="cc2" id="cc2_1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label htmlFor="cc2_1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 1. Easy to see</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-2">
                                            <div className="flex items-center">
                                                <input type="radio" onChange={onHandleChange} value="2" name="cc2" id="cc2_2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label htmlFor="cc2_2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 2. Somewhat easy to see</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-2">
                                            <div className="flex items-center">
                                                <input type="radio" onChange={onHandleChange} value="3" name="cc2" id="cc2_3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label htmlFor="cc2_3" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 3. Difficult to see</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-2">
                                            <div className="flex items-center">
                                                <input type="radio" onChange={onHandleChange} value="4" name="cc2" id="cc2_4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label htmlFor="cc2_4" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 4. Not visible at all</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-2">
                                            <div className="flex items-center">
                                                <input type="radio" onChange={onHandleChange} value="5" name="cc2" id="cc2_5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label htmlFor="cc2_5" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 5. N/A</label>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-4 font-medium w-[80px]" rowSpan={5}><strong>CC3</strong></td>
                                        <td className="whitespace-nowrap px-6 py-4">
                                            <div className="flex font-bold">
                                                If aware of CC (answered codes 1-3 in CC1), how much did the CC help you in your transaction?
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-2">
                                            <div className="flex items-center">
                                                <input type="radio" onChange={onHandleChange} value="1" name="cc3" id="cc3_1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label htmlFor="cc3_1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 1. Helped very much</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-2">
                                            <div className="flex items-center">
                                                <input type="radio" onChange={onHandleChange} value="2" name="cc3" id="cc3_2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label htmlFor="cc3_2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 2. Somewhat helped</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-2">
                                            <div className="flex items-center">
                                                <input type="radio" onChange={onHandleChange} value="3" name="cc3" id="cc3_3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label htmlFor="cc3_3" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 3. Did not help</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-2">
                                            <div className="flex items-center">
                                                <input type="radio" onChange={onHandleChange} value="4" name="cc3" id="cc3_4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label htmlFor="cc3_4" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 4. N/A</label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="table-fixed w-full text-left text-sm font-light">
                                <thead className="border-b font-medium dark:border-neutral-500">
                                    <tr>
                                        <th scope="col" className="px-6 py-4 w-[350px]"></th>
                                        <th scope="col" className="px-6 py-4 text-center">Strongly Disagree</th>
                                        <th scope="col" className="px-6 py-4 text-center">Disagree</th>
                                        <th scope="col" className="px-6 py-4 text-center">Neither Agree nor Disagree</th>
                                        <th scope="col" className="px-6 py-4 text-center">Agree</th>
                                        <th scope="col" className="px-6 py-4 text-center">Strongly Agree</th>
                                        <th scope="col" className="px-6 py-4 text-center">N/A <br></br> Not Applicable</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-4 font-medium"><strong>SQD0</strong>. I am satisfied with the <br></br>service that I availed.</td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="1" name="sqd0" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="2" name="sqd0" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="3" name="sqd0" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="4" name="sqd0" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="5" name="sqd0" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="0" name="sqd0" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-4 font-medium"><strong>SQD1</strong>. I spent a reasonable amount <br></br>of time for my transaction.</td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="1" name="sqd1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="2" name="sqd1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="3" name="sqd1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="4" name="sqd1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="5" name="sqd1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="0" name="sqd1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-4 font-medium"><strong>SQD2</strong>. The office followed the <br></br>transaction’s requirements and steps <br></br>based on the information provided.</td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="1" name="sqd2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="2" name="sqd2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="3" name="sqd2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="4" name="sqd2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="5" name="sqd2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="0" name="sqd2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-4 font-medium"><strong>SQD3</strong>. The steps (including payment) <br></br>I needed to do for my <br></br>transaction were easy and simple.</td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="1" name="sqd3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="2" name="sqd3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="3" name="sqd3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="4" name="sqd3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="5" name="sqd3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="0" name="sqd3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-4 font-medium"><strong>SQD4</strong>. I easily found information <br></br>about my transaction from <br></br>the office or its website.</td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="1" name="sqd4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="2" name="sqd4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="3" name="sqd4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="4" name="sqd4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="5" name="sqd4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="0" name="sqd4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-4 font-medium"><strong>SQD5</strong>. I paid a reasonable amount <br></br>of fees for my transaction. <br></br>(If service was free, <br></br>mark the ‘N/A’ column)</td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="1" name="sqd5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="2" name="sqd5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="3" name="sqd5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="4" name="sqd5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="5" name="sqd5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="0" name="sqd5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-4 font-medium"><strong>SQD6</strong>. I feel the office was <br></br>fair to everyone, or <br></br>“walang palakasan”, during <br></br>my transaction.</td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="1" name="sqd6" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="2" name="sqd6" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="3" name="sqd6" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="4" name="sqd6" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="5" name="sqd6" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="0" name="sqd6" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-4 font-medium"><strong>SQD7</strong>. I was treated courteously <br></br>by the staff, and (if asked for help) <br></br>the staff was helpful.</td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="1" name="sqd7" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="2" name="sqd7" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="3" name="sqd7" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="4" name="sqd7" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="5" name="sqd7" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="0" name="sqd7" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600">
                                        <td className="whitespace-nowrap px-6 py-4 font-medium"><strong>SQD8</strong>. I got what I needed <br></br>from the office, or (if denied) <br></br>denial of request was <br></br>sufficiently explained to me.</td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="1" name="sqd8" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="2" name="sqd8" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="3" name="sqd8" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="4" name="sqd8" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="5" name="sqd8" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4 text-center">
                                            <div className="flex justify-center">
                                                <input type="radio" onChange={onHandleChange} value="0" name="sqd8" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col mt-5">
                <label htmlFor="suggestion" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Suggestions on how we can further improve our services (optional):</label>
                <textarea id="suggestion" name="suggestion" onChange={onHandleChange} rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your thoughts here..."></textarea>
            </div>

            <div className="flex flex-col mt-5">
                <div className="flex flex-nowrap">
                    <TextBoxComponent onChange={onHandleChange} placeholder="Email Address (optional)" name="email_address" id="email_address" cssClass="e-filled" floatLabelType="Auto" />
                </div>
            </div>

            <div className="flex justify-end mt-5">
                {/* <button onClick={onSubmitData} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center min-w-24">
                    <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                    <span>Submit</span>
                </button> */}
                <button onClick={onSubmitData} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                    <img className="h-8 mr-3" src={isave} alt=""/>
                    <span>Save</span>
                </button>
            </div>
        </div>
    )
}

export default NewRating