
export const sqdQuestion = [
    {
        online : {
            en: "SQD0. I am satisfied with the service that I availed.",
            fil: "SQD0. Nasiyahan ako sa serbisyo na aking natanggap sa napuntahan na tanggapan."    
        },
        onsite : {
            en: "SQD0. I am satisfied with the service that I availed.",
            fil: "SQD0. Nasiyahan ako sa serbisyo na aking natanggap sa napuntahan na tanggapan."  
        }
    },
    {
        online : {
            en: "SQD1. I spent a reasonable amount of time for my transaction.",
            fil: "SQD1. Makatwiran ang oras na aking ginugol para sa pagproseso ng aking transaksyon."    
        },
        onsite : {
            en: "SQD1. I spent a reasonable amount of time for my transaction.",
            fil: "SQD1. Makatwiran ang oras na aking ginugol para sa pagproseso ng aking transaksyon."  
        }
    },
    {
        online : {
            en: "SQD2. The office followed the transaction's requirements and steps based on the information provided.",
            fil: "SQD2. Ang opisina ay sumusunod sa mga kinakailangang dokumento at mga hakbang batay sa impormasyong ibinigay."   
        },
        onsite : {
            en: "SQD2. The office followed the transaction's requirements and steps based on the information provided.",
            fil: "SQD2. Ang opisina ay sumusunod sa mga kinakailangang dokumento at mga hakbang batay sa impormasyong ibinigay."  
        }
    },
    {
        online : {
            en: "SQD3. The steps (including payment) I needed to do for my transaction were easy and simple.",
            fil: "SQD3. Ang mga hakbang sa pagproseso, kasama na ang pagbayad ay madali at simple lamang."   
        },
        onsite : {
            en: "SQD3. The steps (including payment) I needed to do for my transaction were easy and simple.",
            fil: "SQD3. Ang mga hakbang sa pagproseso, kasama na ang pagbayad ay madali at simple lamang."  
        }
    },
    {
        online : {
            en: "SQD4. I easily found information about my transaction from the office's website.",
            fil: "SQD4. Mabilis at madali akong nakahanap ng impormasyon tungkol sa aking transaksyon mula sa opisina o sa website nito."   
        },
        onsite : {
            en: "SQD4. I easily found information about my transaction from the office or its website.						",
            fil: "SQD4. Mabilis at madali akong nakahanap ng impormasyon tungkol sa aking transaksyon mula sa opisina o sa website nito."  
        }
    },
    {
        online : {
            en: "SQD5. I paid a reasonable amount of fees for my transaction. (If service was free, mark the 'N/A' column)",
            fil: "SQD5. Nagbayad ako ng makatwirang halaga para sa aking transaksyon. (Kung ang sebisyo ay ibinigay ng libre, maglagay ng tsek sa hanay ng N/A.)"   
        },
        onsite : {
            en: "SQD5. I paid a reasonable amount of fees for my transaction. (If service was free, mark the 'N/A' column)",
            fil: "SQD5. Nagbayad ako ng makatwirang halaga para sa aking transaksyon. (Kung ang sebisyo ay ibinigay ng libre, maglagay ng tsek sa hanay ng N/A.)"  
        }
    },
    {
        online : {
            en: "SQD6. I am confident my online transaction was secure..",
            fil: "SQD6. Pakiramdam ko ay patas ang opisina sa lahat, o “walang palakasan”, sa aking transaksyon."   
        },
        onsite : {
            en: "SQD6. I feel the office was fair to everyone, or “walang palakasan”, during my transaction.",
            fil: "SQD6. Pakiramdam ko ay patas ang opisina sa lahat, o “walang palakasan”, sa aking transaksyon."  
        }
    },
    {
        online : {
            en: "SQD7. The office's online support was available, and (if asked questions) online support was quick to respond..",
            fil: "SQD7. Magalang akong trinato ng mga tauhan, at (kung sakali ako ay humingi ng tulong) alam ko na sila ay handang tumulong sa akin."  
        },
        onsite : {
            en: "SQD7. I was treated courteously by the staff, and (if asked for help) the staff was helpful.",
            fil: "SQD7. Magalang akong trinato ng mga tauhan, at (kung sakali ako ay humingi ng tulong) alam ko na sila ay handang tumulong sa akin."  
        }
    },
    {
        online : {
            en: "SQD8. I got what I needed from the office, or (if denied) denial of request was sufficiently explained to me.",
            fil: "SQD8. Nakuha ko ang kinakailangan ko mula sa tanggapan ng gobyerno, kung tinanggihan man, ito ay sapat na ipinaliwanag sa akin."  
        },
        onsite : {
            en: "SQD8. I got what I needed from the office, or (if denied) denial of request was sufficiently explained to me.",
            fil: "SQD8. Nakuha ko ang kinakailangan ko mula sa tanggapan ng gobyerno, kung tinanggihan man, ito ay sapat na ipinaliwanag sa akin."  
        }
    }
]

export const ccQuestion = [
    {
        en: {
            question: "Which of the following best describes your awareness of a CC?",
            ans1: "I know what a CC is and I saw this office's CC.",
            ans2: "I know what a CC is but I did NOT see this office's CC.",
            ans3: "I learned of the CC only when I saw this office's CC.",
            ans4: "I do not know what a CC is and I did not see one in this office. (Answer 'N/A' on CC2 and CC3)",
            ans5: "",
        },
        fil: {
            question: "Alin sa mga sumusunod ang naglalarawan sa iyong kaalaman sa CC?",
            ans1: "Alam ko ang CC at nakita ko ito sa napuntahang opisina",
            ans2: "Alam ko ang CC pero hindi ko ito nakita sa napuntahang opisina",
            ans3: "Nalaman ko ang CC nang makita ko ito sa napuntahang opisina",
            ans4: "Hindi ko alam kung ano ang CC at wala akong nakita sa napuntahang opisina (Lagyan ng tsek ang 'N/A' sa CC2 at CC3 kapag ito ang iyong sagot)",
            ans5: "",
        }
    },
    {
        en: {
            question: "If aware of CC (answered 1-3 in CC1), would you say that the CC of this office was …?",
            ans1: "Easy to see",
            ans2: "Somewhat easy to see",
            ans3: "Difficult to see",
            ans4: "Not visible at all",
            ans5: "N/A",
        },
        fil: {
            question: "Kung alam ang CC (Nag-tsek sa opsyon 1-3 sa CC1), masasabi mo ba na ang CC nang napuntahang opisina ay…",
            ans1: "Madaling makita",
            ans2: "Medyo madaling makita",
            ans3: "Mahirap makita",
            ans4: "Hindi makita",
            ans5: "N/A",
        }
    },
    {
        en: {
            question: "If aware of CC (answered codes 1-3 in CC1), how much did the CC help you in your transaction?",
            ans1: "Helped very much",
            ans2: "Somewhat helped",
            ans3: "Did not help",
            ans4: "N/A",
            ans5: "",
        },
        fil: {
            question: "Kung alam ang CC (nag-tsek sa opsyon 1-3 sa CC1), gaano nakatulong ang CC sa transaksyon mo?",
            ans1: "Sobrang nakatulong",
            ans2: "Nakatulong naman",
            ans3: "Hindi nakatulong",
            ans4: "N/A",
            ans5: "",
        }
    }
]

export const sqdAnswer = [
    {
        en: {
            ans1: "Strongly Disagree",
            ans2: "Disagree",
            ans3: "Neither Agree nor Disagree",
            ans4: "Agree",
            ans5: "Strongly Agree"
        },
        fil: {
            ans1: "Lubos na hindi sumasang-ayon",
            ans2: "Hindi sumasang-ayon",
            ans3: "Walang kinikilingan",
            ans4: "Sumasang-ayon",
            ans5: "Labis na sumasang-ayon"
        }  
    } 
]

export const sqdOthers = [
    {
        en: {
            suggestion: "Suggestions on how we can further improve our services (optional)",
            write_thought: "Write your thoughts here...",
            email_textbox: "Email Address (optional) ",
            submit_button: "Submit"
        },
        fil: {
            suggestion: "Mga suhestiyon kung paano pa mapapabuti pa ang aming mga serbisyo (opsyonal)",
            write_thought: "Isulat ang iyong mga saloobin dito...",
            email_textbox: "Email Address (opsyonal)",
            submit_button: "Ipasa"
        }  
    } 
]