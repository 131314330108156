import React from 'react';
import { FiHome } from 'react-icons/fi';
import { BsFillInfoSquareFill, BsFillInboxFill, BsCardChecklist } from 'react-icons/bs';
import { IoIosSchool, IoIosHome, IoIosCreate, IoIosGitCompare, IoIosHammer, IoIosPeople, IoIosMap, IoIosHand } from 'react-icons/io';
import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

export var Region = []
export var Rater = []
export var Offices = []

const showRegion = () => {
  api.get('get/get-region')
  .then(res => {
    Region = res.data
  })
  .catch(err => {
      console.log(err)
  })
}

const showRaterList = () => {
  api.get('get/rater')
  .then(res => {
    Rater = res.data
  })
  .catch(err => {
      console.log(err)
  })
}

const showOfficeList = () => {
  api.get('get/all-office')
  .then(res => {
    Offices = res.data
  })
  .catch(err => {
      console.log(err)
  })
}

showRegion()
showRaterList()
showOfficeList()

export const gridOrderImage = (props) => (
  <div>
    <img
      className="rounded-xl h-20 md:ml-3"
      src={props.ProductImage}
      alt="order-item"
    />
  </div>
)

export const gridOrderStatus = (props) => (
  <button
    type="button"
    style={{ background: props.StatusBg }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.Status}
  </button>
)

export const links = [
  {
    title: 'Dashboard',
    links: [
      {
        name: 'home',
        label: 'Home',
        icon: <FiHome />,
      },
    ],
  },
  {
    title: 'Pages',
    links: [
      {
        name: 'office',
        label: 'Office',
        icon: <IoIosHome />,
      },
      {
        name: 'campus',
        label: 'Campus',
        icon: <IoIosSchool />,
      },
      {
        name: 'services',
        label: 'Services',
        icon: <IoIosHammer />,
      },
    ],
  },
  {
    title: 'Rating',
    links: [
      {
        name: 'rating',
        label: 'Rating',
        icon: <IoIosGitCompare />,
      },
      {
        name: 'new-rating',
        label: 'New Rating',
        icon: <IoIosCreate />,
      },
    ],
  },
  {
    title: 'Reports',
    links: [
      {
        name: 'demographic-profile',
        label: 'Demographic Profile',
        icon: <IoIosPeople />,
      },
      {
        name: 'count-cc-sqd',
        label: 'Count of CC and SQD',
        icon: <IoIosMap />,
      },   
      {
        name: 'overall-score',
        label: 'Overall score per service',
        icon: <IoIosHand />,
      }, 
    ],
  },
]

export const chatData = [
 
]

export const themeColors = [
  {
    name: 'blue-theme',
    color: '#1A97F5',
  },
  {
    name: 'green-theme',
    color: '#03C9D7',
  },
  {
    name: 'purple-theme',
    color: '#7352FF',
  },
  {
    name: 'red-theme',
    color: '#FF5C8E',
  },
  {
    name: 'indigo-theme',
    color: '#1E4DB7',
  },
  {
    color: '#FB9678',
    name: 'orange-theme',
  },
]

export const userProfileData = [
  {
    icon: <BsFillInfoSquareFill />,
    title: 'My Profile',
    desc: 'Account Settings',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
  },
  {
    icon: <BsFillInboxFill />,
    title: 'My Inbox',
    desc: 'Messages & Emails',
    iconColor: 'rgb(0, 194, 146)',
    iconBg: 'rgb(235, 250, 242)',
  },
  {
    icon: <BsCardChecklist />,
    title: 'My Tasks',
    desc: 'To-do and Daily Tasks',
    iconColor: 'rgb(255, 244, 229)',
    iconBg: 'rgb(254, 201, 15)',
  },
]

/*******  ADDITIONAL  ********/

export const sexList = [
  {id: 'F', name: 'Female'},
  {id: 'M', name: 'Male'},
  {id: 'L', name: 'LGBTQIA+'}
]

export const ageList = [
  {id: '19 or lower', name: '19 or lower'},
  {id: '20-34', name: '20-34'},
  {id: '35-49', name: '35-49'},
  {id: '50-64', name: '50-64'},
  {id: '65 or higher', name: '65 or higher'},
  {id: 'did not specify', name: 'did not specify'}
]

export const introText = [
  {
    en: {
      title: "HELP US SERVE YOU BETTER!",
      desc: "This Client Satisfaction Survey (CSS) tracks the customer experience of CBSUA offices. Your feedback on your recently concluded transaction will help this office provide a better service. Personal information shared will be kept confidential and you always have the option to not answer this form.",
      button: "Let's Start"
    },
    fil: {
      title: "TULUNGAN MO KAMI MAS MAPABUTI ANG AMING MGA PROSESO AT SERBISYO!",
      desc: "Ang Client Satisfaction Survey (CSS) ay naglalayong masubaybayan ang karanasan ng taumbayan hinggil sa kanilang pakikitransaksyon sa mga tanggapan ng CBSUA. Makatutulong ang inyong kasagutan ukol sa inyong naging karanasan sa kakatapos lamang na transaksyon, upang mas mapabuti at lalong mapahusay ang aming serbisyo publiko. Ang personal na impormasyon na iyong ibabahagi ay mananatiling kumpidensyal. Maaari ring piliin na hindi sagutan ang sarbey na ito.",
      button: "Simulan Natin"
    }
  }
]

export const RaterInfoText = [
  {
    en: {
      client_type: "Client type",
      date: "Date",
      sex: "Sex",
      location: "City/Mun.&Province of residence",
      age: "Age",
      service_avail: "Service Availed",
      intro: "INSTRUCTIONS",
      cc_guide: "Select your answer to the Citizen's Charter (CC) questions. The Citizen's Charter is an official document that reflects the services of a government agency/office including its requirements, fees, and processing times among others.",
      sqd_guide: "For SQD 0-8, please select your answer on the column that best corresponds to your answer."
    },
    fil: {
      client_type: "Uri ng Kliyente",
      date: "Petsa",
      sex: "Kasarian",
      location: "Syudad/Munisipyo & Probinsya",
      age: "Edad",
      service_avail: "Uri ng transaksyon o serbisyo",
      intro: "PANUTO",
      cc_guide: "Piliin ang iyong sagot sa mga sumusunod na katanungan tungkol sa Citizen’s Charter (CC). Ito ay isang opisyal na dokumento na naglalaman ng mga serbisyo sa isang ahensya/opisina ng gobyerno, makikita rito ang mga kinakailangan na dokumento, kaukulang bayarin, at pangkabuuang oras ng pagproseso.",
      sqd_guide: "Para sa SQD 0-8, piliin sa hanay na pinakaangkop sa iyong sagot."
    }
  }
]

export const categoryList = [
  {id: '1', name: 'Age'},
  {id: '2', name: 'Sex'},
  {id: '3', name: 'Offices'},
  {id: '4', name: 'Rater'},
]