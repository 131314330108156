import React from "react";

import { Header } from "../../components";

const CCSQDResult = () => {
    return (
        <div className="m-2 m-4 p-2 md:p-10 bg-white rounded-3xl">
            <Header category="Reports" title="Count of CC and SQD" />
                
        </div>
    )
}

export default CCSQDResult