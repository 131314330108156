import React from "react";

import { Header } from "../components";

const Home = () => {
  return (
    <div className="m-2 m-4 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Dashboard" title="Home" />

    </div>  
  )
}

export default Home;
