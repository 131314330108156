import React from 'react'

import cbsua_log from "../data/cbsua_logo.png";
import tuvr from "../data/tuvr.png";

const SurveyHeader = () => {
  return (
    <div className="flex items-center justify-center dark:bg-main-dark-bg">
        <div className="w-[1020px] bg-white border border-gray-200 rounded-lg shadow-md p-2 mt-1">
            <div className="grid lg:grid-cols-[40%_60%]">
                <div className="flex justify-center">
                    <div>
                        <img className="object-contain h-32 w-32" src={cbsua_log} alt=""/>
                        <img className="object-contain w-32" src={tuvr} alt=""/>
                    </div>
                </div>
                <div className="mt-2 text-center sm:text-left">
                    <p className="font-bold text-sm px-2 py-0.5"> Republic of the Philippines </p>
                    <p className="font-bold text-sm px-2 py-0.5"> CENTRAL BICOL STATE UNIVERSITY OF AGRICULTURE </p>
                    <p className="font-bold text-sm px-2 py-0.5"> San Jose, Pili, Camarines Sur 4418 </p>
                    <p className="font-sm text-sm px-2 py-0.5 italic"> Website: <a href="https://www.cbsua.edu.ph" target="_blank" rel="noreferrer">www.cbsua.edu.ph</a> </p>
                    <p className="font-sm text-sm px-2 py-0.5 italic"> Email Address: op@cbsua.edu.ph </p>
                    <p className="font-sm text-sm px-2 py-0.5 italic"> Trunkline: (054) 871-5531-33 local 101 </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SurveyHeader