import React from 'react'
import Moment from 'moment';

import { sexList } from '../data/list';

const RaterInfo = props => {
  const {
    dataEntry,
    raterList,
    servicesList,
    RaterInfoText,
    MunName,
    ProvName
  } = props
 
  return (
    <div className="flex items-center justify-center dark:bg-main-dark-bg">
        <div className="w-[1020px] bg-white border border-gray-200 rounded-lg shadow-md p-6 mt-1">
            <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-2">
                <div><p className="font-semibold text-sm"> { RaterInfoText.client_type }: <span className="font-normal ml-2"> { raterList.find((item) => item.id === dataEntry.rater).rater_name } </span></p></div>
                <div><p className="font-semibold text-sm"> { RaterInfoText.date }: <span className="font-normal ml-2"> { Moment(dataEntry.rating_date).format('DD-MM-YYYY') } </span></p></div>
                <div><p className="font-semibold text-sm"> { RaterInfoText.sex }: <span className="font-normal ml-2"> { sexList.find((item) => item.id === dataEntry.sex).name } </span></p></div>
                <div><p className="font-semibold text-sm"> { RaterInfoText.age }: <span className="font-normal ml-2"> { dataEntry.age } </span></p></div>
            </div>
            <div className="grid gap-2 mt-2">
                <div><p className="font-semibold text-sm"> { RaterInfoText.location }: <span className="font-normal ml-2"> { MunName + ", " + ProvName } </span></p></div>
                <div><p className="font-semibold text-sm"> { RaterInfoText.service_avail }: <span className="font-normal ml-2"> 
                { 
                  dataEntry.service_avail.map((result) => {
                    return servicesList.find((item) => item.value === result).label + " "
                  })
                } 
                </span></p></div>
            </div>
        </div>
    </div>
  )
}

export default RaterInfo
