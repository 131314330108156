import React from 'react'
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import PropagateLoader from "react-spinners/PropagateLoader";

import { sqdQuestion, sqdAnswer, sqdOthers } from '../data/questions';
import isave from "../data/save-64-c.png";

const SQDTemplate = pros => {
    const {
        isQHidden,
        survey_where,
        lang,
        onHandleChange,
        onSubmitData,
        isSubmitShow,
        isPreLoading
    } = pros

    const override = {
        display: "block",
        margin: "15px auto",
        borderColor: "red",
        position: "absolute"
    };

    return (
        <div>
            <div hidden={isQHidden.sqd0}>
                <div className="flex items-center px-2 py-1 bg-gray-500 bg-opacity-30 rounded">
                    { sqdQuestion[0][survey_where][lang] }
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="5" name="sqd0" id="sqd0_5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd0_5" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128515; { sqdAnswer[0][lang].ans5 }</label>
                    </div>      
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="4" name="sqd0" id="sqd0_4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd0_4" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128522; { sqdAnswer[0][lang].ans4 }</label>
                    </div>    
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="3" name="sqd0" id="sqd0_3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd0_3" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128528; { sqdAnswer[0][lang].ans3 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="2" name="sqd0" id="sqd0_2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex ">
                        <label htmlFor="sqd0_2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128542; { sqdAnswer[0][lang].ans2 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="1" name="sqd0" id="sqd0_1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd0_1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128550; { sqdAnswer[0][lang].ans1 }</label>     
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="0" name="sqd0" id="sqd0_6" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd0_6" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">N/A (Not Applicable)</label>
                    </div>
                </div>
            </div>

            <div hidden={isQHidden.sqd1}>
                <div className="flex items-center px-2 py-1 bg-gray-500 bg-opacity-30 rounded">
                    { sqdQuestion[1][survey_where][lang] }
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="5" name="sqd1" id="sqd1_5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd1_5" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128515; { sqdAnswer[0][lang].ans5 }</label>
                    </div>         
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="4" name="sqd1" id="sqd1_4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd1_4" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128522; { sqdAnswer[0][lang].ans4 }</label>
                    </div>   
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="3" name="sqd1" id="sqd1_3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd1_3" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128528; { sqdAnswer[0][lang].ans3 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="2" name="sqd1" id="sqd1_2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex ">
                        <label htmlFor="sqd1_2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128542; { sqdAnswer[0][lang].ans2 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="1" name="sqd1" id="sqd1_1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd1_1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128550; { sqdAnswer[0][lang].ans1 }</label>     
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="0" name="sqd1" id="sqd1_6" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd1_6" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">N/A (Not Applicable)</label>
                    </div>
                </div>
            </div>

            <div hidden={isQHidden.sqd2}>
                <div className="flex items-center px-2 py-1 bg-gray-500 bg-opacity-30 rounded">
                    { sqdQuestion[2][survey_where][lang] }
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="5" name="sqd2" id="sqd2_5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd2_5" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128515; { sqdAnswer[0][lang].ans5 }</label>
                    </div>         
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="4" name="sqd2" id="sqd2_4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd2_4" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128522; { sqdAnswer[0][lang].ans4 }</label>
                    </div>  
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="3" name="sqd2" id="sqd2_3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd2_3" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128528; { sqdAnswer[0][lang].ans3 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="2" name="sqd2" id="sqd2_2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex ">
                        <label htmlFor="sqd2_2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128542; { sqdAnswer[0][lang].ans2 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="1" name="sqd2" id="sqd2_1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd2_1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128550; { sqdAnswer[0][lang].ans1 }</label>     
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="0" name="sqd2" id="sqd2_6" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd2_6" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">N/A (Not Applicable)</label>
                    </div>
                </div>
            </div>

            <div hidden={isQHidden.sqd3}>
                <div className="flex items-center px-2 py-1 bg-gray-500 bg-opacity-30 rounded">
                    { sqdQuestion[3][survey_where][lang] }
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="5" name="sqd3" id="sqd3_5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd3_5" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128515; { sqdAnswer[0][lang].ans5 }</label>
                    </div>          
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="4" name="sqd3" id="sqd3_4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd3_4" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128522; { sqdAnswer[0][lang].ans4 }</label>
                    </div>    
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="3" name="sqd3" id="sqd3_3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd3_3" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128528; { sqdAnswer[0][lang].ans3 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="2" name="sqd3" id="sqd3_2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex ">
                        <label htmlFor="sqd3_2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128542; { sqdAnswer[0][lang].ans2 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="1" name="sqd3" id="sqd3_1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd3_1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128550; { sqdAnswer[0][lang].ans1 }</label>     
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="0" name="sqd3" id="sqd3_6" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd3_6" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">N/A (Not Applicable)</label>
                    </div>
                </div>
            </div>

            <div hidden={isQHidden.sqd4}>
                <div className="flex items-center px-2 py-1 bg-gray-500 bg-opacity-30 rounded">
                    { sqdQuestion[4][survey_where][lang] }
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="5" name="sqd4" id="sqd4_5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd4_5" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128515; { sqdAnswer[0][lang].ans5 }</label>
                    </div>         
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="4" name="sqd4" id="sqd4_4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd4_4" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128522; { sqdAnswer[0][lang].ans4 }</label>
                    </div>    
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="3" name="sqd4" id="sqd4_3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd4_3" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128528; { sqdAnswer[0][lang].ans3 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="2" name="sqd4" id="sqd4_2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex ">
                        <label htmlFor="sqd4_2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128542; { sqdAnswer[0][lang].ans2 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="1" name="sqd4" id="sqd4_1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd4_1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128550; { sqdAnswer[0][lang].ans1 }</label>     
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="0" name="sqd4" id="sqd4_6" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd4_6" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">N/A (Not Applicable)</label>
                    </div>
                </div>
            </div>

            <div hidden={isQHidden.sqd5}>
                <div className="flex items-center px-2 py-1 bg-gray-500 bg-opacity-30 rounded">
                    { sqdQuestion[5][survey_where][lang] }
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="5" name="sqd5" id="sqd5_5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd5_5" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128515; { sqdAnswer[0][lang].ans5 }</label>
                    </div>          
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="4" name="sqd5" id="sqd5_4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd5_4" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128522; { sqdAnswer[0][lang].ans4 }</label>
                    </div>   
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="3" name="sqd5" id="sqd5_3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd5_3" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128528; { sqdAnswer[0][lang].ans3 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="2" name="sqd5" id="sqd5_2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex ">
                        <label htmlFor="sqd5_2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128542; { sqdAnswer[0][lang].ans2 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="1" name="sqd5" id="sqd5_1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd5_1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128550; { sqdAnswer[0][lang].ans1 }</label>     
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="0" name="sqd5" id="sqd5_6" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd5_6" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">N/A (Not Applicable)</label>
                    </div>
                </div>
            </div>

            <div hidden={isQHidden.sqd6}>
                <div className="flex items-center px-2 py-1 bg-gray-500 bg-opacity-30 rounded">
                    { sqdQuestion[6][survey_where][lang] }
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="5" name="sqd6" id="sqd6_5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd6_5" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128515; { sqdAnswer[0][lang].ans5 }</label>
                    </div>          
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="4" name="sqd6" id="sqd6_4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd6_4" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128522; { sqdAnswer[0][lang].ans4 }</label>
                    </div>   
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="3" name="sqd6" id="sqd6_3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd6_3" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128528; { sqdAnswer[0][lang].ans3 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="2" name="sqd6" id="sqd6_2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex ">
                        <label htmlFor="sqd6_2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128542; { sqdAnswer[0][lang].ans2 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="1" name="sqd6" id="sqd6_1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd6_1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128550; { sqdAnswer[0][lang].ans1 }</label>     
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="0" name="sqd6" id="sqd6_6" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd6_6" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">N/A (Not Applicable)</label>
                    </div>
                </div>
            </div>

            <div hidden={isQHidden.sqd7}>
                <div className="flex items-center px-2 py-1 bg-gray-500 bg-opacity-30 rounded">
                    { sqdQuestion[7][survey_where][lang] }
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="5" name="sqd7" id="sqd7_5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd7_5" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128515; { sqdAnswer[0][lang].ans5 }</label>
                    </div>         
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="4" name="sqd7" id="sqd7_4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd7_4" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128522; { sqdAnswer[0][lang].ans4 }</label>
                    </div>  
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="3" name="sqd7" id="sqd7_3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd7_3" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128528; { sqdAnswer[0][lang].ans3 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="2" name="sqd7" id="sqd7_2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex ">
                        <label htmlFor="sqd7_2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128542; { sqdAnswer[0][lang].ans2 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="1" name="sqd7" id="sqd7_1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd7_1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128550; { sqdAnswer[0][lang].ans1 }</label>     
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="0" name="sqd7" id="sqd7_6" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd7_6" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">N/A (Not Applicable)</label>
                    </div>
                </div>
            </div>

            <div hidden={isQHidden.sqd8}>
                <div className="flex items-center px-2 py-1 bg-gray-500 bg-opacity-30 rounded">
                    { sqdQuestion[8][survey_where][lang] }
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="5" name="sqd8" id="sqd8_5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd8_5" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128515; { sqdAnswer[0][lang].ans5 }</label>
                    </div>         
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="4" name="sqd8" id="sqd8_4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd8_4" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128522; { sqdAnswer[0][lang].ans4 }</label>
                    </div>    
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="3" name="sqd8" id="sqd8_3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd8_3" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128528; { sqdAnswer[0][lang].ans3 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="2" name="sqd8" id="sqd8_2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex ">
                        <label htmlFor="sqd8_2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128542; { sqdAnswer[0][lang].ans2 }</label>
                    </div>
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="1" name="sqd8" id="sqd8_1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd8_1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&#128550; { sqdAnswer[0][lang].ans1 }</label>     
                    </div> 
                </div>
                <div className="flex items-center px-2 py-1">
                    <div className="flex mr-1">
                        <input type="radio" onChange={onHandleChange} value="0" name="sqd8" id="sqd8_6" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className="flex">
                        <label htmlFor="sqd8_6" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">N/A (Not Applicable)</label>
                    </div>
                </div>
                <div className="flex flex-col mt-5">
                    <label htmlFor="suggestion" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{ sqdOthers[0][lang].suggestion }:</label>
                    <textarea id="suggestion" name="suggestion" onChange={onHandleChange} rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={ sqdOthers[0][lang].write_thought }></textarea>
                </div>

                <div className="flex flex-col mt-5">
                    <div className="flex flex-nowrap">
                        <TextBoxComponent onChange={onHandleChange} placeholder={ sqdOthers[0][lang].email_textbox } name="email_address" id="email_address" cssClass="e-filled" floatLabelType="Auto" />
                    </div>
                </div>
                
                <div className="flex justify-center">
                    <PropagateLoader
                        color="#36d7b7"
                        loading={isPreLoading}
                        cssOverride={override}
                        size={25}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>

                {
                    isSubmitShow ?
                    <div className="flex justify-end mt-5">
                        <button name="submit" id="submit" onClick={onSubmitData} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                            <img className="h-8 mr-3" src={isave} alt=""/>
                            <span>{ sqdOthers[0][lang].submit_button }</span>
                        </button>
                    </div>
                    : ''
                }
               
            </div>
        </div>
    )
}

export default SQDTemplate