import React, {useEffect, useState} from 'react'
import axios from 'axios'

import { sexList, ageList, Offices, Rater } from '../data/list';

const ReportDP = props => {
    const {
        tableLabel
    } = props

    const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
    })

    const [rData, setRData] = useState([])
    const [computedResult, setComputedResult] = useState([])
    const [totalSurvey, setTotalSurvey] = useState({
        'online': 0,
        'onsite': 0
    })

    useEffect(() => {
     
        if (tableLabel === 'Age' ) {
            setRData(ageList)
            setComputedResult([])
            setTotalSurvey([])
            getDemographicProfile(1, 'age')
        } else if (tableLabel === 'Sex' ) {
            setRData(sexList)
            setComputedResult([])
            setTotalSurvey([])
            getDemographicProfile(1, 'sex')
        } else if (tableLabel === 'Offices' ) {
            let c_office = Offices.filter(item => item.campus_id === 1).map(filterOffice => { return filterOffice })
            setRData(c_office)
            setComputedResult([])
            setTotalSurvey([])
            getDemographicProfile(1, 'office_id')
        } else if (tableLabel === 'Rater' ) {
            setRData(Rater)
            setComputedResult([])
            setTotalSurvey([])
            getDemographicProfile(1, 'rater_id')
        } else {
            setRData([])
            setComputedResult([])
            setTotalSurvey([])
        }
            
    }, [tableLabel])

    const getDemographicProfile = (id, type) => {
        api.get('get/get-demographic-profile/' + id + "/" + type)
        .then(res => {
            setComputedResult(res.data)
            setTotalSurvey({...totalSurvey, online: res.data['sum_online'],  onsite: res.data['sum_onsite']})
        })
        .catch(err => {
            console.log(err)
        })
    }

    return (
        <div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-sm text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-center">
                            { tableLabel }
                        </th>
                        <th scope="col" className="px-6 py-3 text-center">
                            External
                        </th>
                        <th scope="col" className="px-6 py-3 text-center">
                            Internal
                        </th>
                        <th scope="col" className="px-6 py-3 text-center">
                            Overall
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { rData.map((result, index) => {
                        return (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={ index }>
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    { result.name ? result.name : result.rater_name }
                                </th>
                                { Object.keys(computedResult).length > 0 &&
                                    computedResult['online'].map((ol, indx) => {
                                        if (tableLabel === 'Age') {
                                            if (ol.age === result.name) {
                                                return (
                                                    <td className="px-6 py-4 text-center" key={ indx }>
                                                        { ((ol.total / totalSurvey.online) * 100).toFixed(2) }%
                                                    </td>  
                                                ) 
                                            }
                                        } else if (tableLabel === 'Sex') {
                                            if (ol.sex === result.id) {
                                                return (
                                                    <td className="px-6 py-4 text-center" key={ indx }>
                                                        { ((ol.total / totalSurvey.online) * 100).toFixed(2) }%
                                                    </td>  
                                                ) 
                                            }    
                                        } else if (tableLabel === 'Rater') {
                                            if (ol.rater_id === result.id) {
                                                return (
                                                    <td className="px-6 py-4 text-center" key={ indx }>
                                                        { ((ol.total / totalSurvey.online) * 100).toFixed(2) }%
                                                    </td>  
                                                ) 
                                            }    
                                        } else if (tableLabel === 'Offices') {
                                            if (ol.office_id === result.id) {
                                                return (
                                                    <td className="px-6 py-4 text-center" key={ indx }>
                                                        { ((ol.total / totalSurvey.online) * 100).toFixed(2) }%
                                                    </td>  
                                                ) 
                                            }    
                                        }
                                    })
                                }

                                { Object.keys(computedResult).length > 0 &&
                                    computedResult['onsite'].map((olx, indx) => {
                                        if (tableLabel === 'Age') {
                                            if (olx.age === result.name) {
                                                return (
                                                    <td className="px-6 py-4 text-center" key={ indx }>
                                                        { ((olx.total / totalSurvey.onsite) * 100).toFixed(2) }%
                                                    </td>  
                                                )   
                                            }
                                        } else if (tableLabel === 'Sex') {
                                            if (olx.sex === result.id) {
                                                return (
                                                    <td className="px-6 py-4 text-center" key={ indx }>
                                                        { ((olx.total / totalSurvey.onsite) * 100).toFixed(2) }%
                                                    </td>  
                                                )   
                                            }    
                                        } else if (tableLabel === 'Rater') {
                                            if (olx.rater_id === result.id) {
                                                return (
                                                    <td className="px-6 py-4 text-center" key={ indx }>
                                                        { ((olx.total / totalSurvey.onsite) * 100).toFixed(2) }%
                                                    </td>  
                                                )   
                                            }    
                                        } else if (tableLabel === 'Offices') {
                                            if (olx.office_id === result.id) {
                                                return (
                                                    <td className="px-6 py-4 text-center" key={ indx }>
                                                        { ((olx.total / totalSurvey.onsite) * 100).toFixed(2) }%
                                                    </td>  
                                                )   
                                            }    
                                        }
                                    })
                                }

                                { Object.keys(computedResult).length > 0 &&
                                    computedResult['onsite'].map((olx, indx) => {
                                        if (tableLabel === 'Age') {
                                            if (olx.age === result.name) {
                                                return (
                                                    computedResult['online'].map((ol) => {
                                                        if (ol.age === olx.age) {
                                                            return (
                                                                <td className="px-6 py-4 text-center" key={ indx }>
                                                                    { (((olx.total + ol.total) / (totalSurvey.online + totalSurvey.onsite)) * 100).toFixed(2) }%
                                                                </td>  
                                                            )   
                                                        }
                                                    })
                                                )
                                            }
                                        } else if (tableLabel === 'Sex') {
                                            if (olx.sex === result.id) {
                                                return (
                                                    computedResult['online'].map((ol) => {
                                                        if (ol.sex === olx.sex) {
                                                            return (
                                                                <td className="px-6 py-4 text-center" key={ indx }>
                                                                    { (((olx.total + ol.total) / (totalSurvey.online + totalSurvey.onsite)) * 100).toFixed(2) }%
                                                                </td>  
                                                            )   
                                                        }
                                                    })
                                                )
                                            }   
                                        } else if (tableLabel === 'Rater') {
                                            if (olx.rater_id === result.id) {
                                                return (
                                                    computedResult['online'].map((ol) => {
                                                        if (ol.rater_id === olx.rater_id) {
                                                            return (
                                                                <td className="px-6 py-4 text-center" key={ indx }>
                                                                    { (((olx.total + ol.total) / (totalSurvey.online + totalSurvey.onsite)) * 100).toFixed(2) }%
                                                                </td>  
                                                            )   
                                                        }
                                                    })
                                                )
                                            }   
                                        } else if (tableLabel === 'Offices') {
                                            if (olx.office_id === result.id) {
                                                return (
                                                    computedResult['online'].map((ol) => {
                                                        if (ol.office_id === olx.office_id) {
                                                            return (
                                                                <td className="px-6 py-4 text-center" key={ indx }>
                                                                    { (((olx.total + ol.total) / (totalSurvey.online + totalSurvey.onsite)) * 100).toFixed(2) }%
                                                                </td>  
                                                            )   
                                                        }
                                                    })
                                                )
                                            }   
                                        }
                                    })
                                }
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export  default ReportDP