import React , { useState } from "react";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

import { Query } from '@syncfusion/ej2-data';

import { Header, ReportDP } from "../../components";
import { categoryList } from '../../data/list';

const DemographicProfile = () => {

    const category_fields = { text: 'name', value: 'id' };
    const [selectedText, setSelectedTextState] = useState('')

    const onHandleChange = (e) => {
        const {name, value} = e.target
        setSelectedTextState(e.target.itemData.name)
    }

    const onFiltering = FilteringEventArgs => {
        let query = new Query();
        query = (FilteringEventArgs.text !== '') ? query.where('name', 'startswith', FilteringEventArgs.text, true) : query;
        FilteringEventArgs.updateData(categoryList, query);
    }

    return (
        <div className="m-2 m-4 p-2 md:p-10 bg-white rounded-3xl">
            <Header category="Reports" title="Demographic Profile" />

            <div className="flex mb-2 justify-end">
                <div className="border px-2 py-1">
                    <div className="flex w-[280px]">
                        <DropDownListComponent onChange={onHandleChange} name="category" id="category" dataSource={categoryList} filtering={onFiltering.bind(this)} filterBarPlaceholder='Search category' allowFiltering={true} fields={category_fields} placeholder="Select Category" />   
                    </div>
                </div>
            </div>

            <div className="relative overflow-x-auto">
                {
                    selectedText ?
                    <ReportDP tableLabel={selectedText} />
                    :
                    <div className="mt-7 py-5 border">
                        <p className="text-sm font-medium text-red-900 text-center"> No record found. </p>
                    </div>
                }
                
            </div>
     
        </div>
    )
}

export default DemographicProfile
