import React from "react";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import Select from 'react-select';

import { Query } from '@syncfusion/ej2-data';

import '../css/style.css';

import GeoLocationClient from "./GeoLocationClient";
import { sexList, ageList } from '../data/list';

import isubmit from "../data/submit.png";
import iqr from "../data/qr.png";

const RaterCredential = pros => {
    const {
        onHandleChange,
        servicesList,
        submitInfo,
        updateValueFromState,
        selectedServicesValue,
        officeList,
        campusList,
        raterList,
        msgVisible,
        dataEntry,
        onOrCloseQr
    } = pros

    const dateValue = new Date();
    const disable = true;
    
    const campus_fields = { text: 'campus_name', value: 'id' }
    const office_fields = { text: 'name', value: 'id' }
    const rater_fields = { text: 'rater_name', value: 'id' }
    const sex_fields = { text: 'name', value: 'id' }
    const age_fields = { text: 'name', value: 'id' }
    
    const onHandleChangeSelect2 = (e) => {
        selectedServicesValue(Array.isArray(e) ? e.map(x => x.value) : [])
    }

    const onFiltering = FilteringEventArgs => {
        let query = new Query();
        query = (FilteringEventArgs.text !== '') ? query.where('name', 'startswith', FilteringEventArgs.text, true) : query;
        FilteringEventArgs.updateData(officeList, query);
    }
    
    return (
        <div className="flex items-center justify-center dark:bg-main-dark-bg">
            
            <div className="w-full max-w-xl bg-white border border-gray-200 rounded-lg shadow-md p-6 mt-2">
                {
                    msgVisible ?
                    <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                        <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                        </svg>
                        <span className="sr-only">Info</span>
                        <div className="ml-2">
                            <span className="font-medium">Message alert!</span> All fields are required, Please check and try again.
                        </div>
                    </div>
                    : ''
                }

                <div className="flex gap-2 mt-2 border-solid border-1 dark:border-neutral-500 px-3 py-1">
                    <div className="flex-1">
                        <DatePickerComponent strictMode={disable} format="dd/MM/yyyy" value={dateValue} max={dateValue} onChange={onHandleChange} name="rating_date" id="rating_date"></DatePickerComponent>
                    </div>
                    <div className="flex-1">
                        <DropDownListComponent style={{fontSize: "15px"}} name="rater" id="rater" onChange={onHandleChange} dataSource={raterList} fields={rater_fields} placeholder="Select a rater" />
                    </div>
                </div>

                <div className="flex mt-2 border-solid border-1 dark:border-neutral-500 px-3 py-1">
                    <div className="flex-1">
                        <DropDownListComponent style={{fontSize: "15px"}} value={dataEntry.campus_id} name="campus_id" id="campus_id" onChange={onHandleChange} dataSource={campusList} filtering={onFiltering.bind(this)} filterBarPlaceholder='Search a campus' allowFiltering={true} fields={campus_fields} placeholder="Select a campus" />   
                    </div>
                </div>

                <div className="flex mt-2 border-solid border-1 dark:border-neutral-500 px-3 py-1">
                    <div className="flex-1">
                        <DropDownListComponent style={{fontSize: "15px"}} value={dataEntry.office} name="office" id="office" onChange={onHandleChange} dataSource={officeList} filtering={onFiltering.bind(this)} filterBarPlaceholder='Search a office' allowFiltering={true} fields={office_fields} placeholder="Select a office" />   
                    </div>
                </div>

                <GeoLocationClient updateValueFromState={updateValueFromState} />

                <div className="flex gap-2 mt-2 border-solid border-1 dark:border-neutral-500 px-3 py-1">
                    <div className="flex-1">
                        <DropDownListComponent style={{fontSize: "15px"}} name="age" id="age" onChange={onHandleChange} dataSource={ageList} fields={age_fields} placeholder="Select a age" />
                    </div>
                    <div className="flex-1">
                        <DropDownListComponent style={{fontSize: "15px"}} name="sex" id="sex" onChange={onHandleChange} dataSource={sexList} fields={sex_fields} placeholder="Select a sex" />
                    </div>
                </div>

                <div className="flex mt-2 border-solid border-1 dark:border-neutral-500 px-3 py-1">
                    <div className="flex-1">
                        <Select
                            isMulti
                            name="service_avail"
                            options={servicesList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select a service avail..."
                            onChange={onHandleChangeSelect2}
                        />
                    </div>
                </div>
                
                <div className="flex justify-center mt-5">
                    <button name="submit" id="submit" onClick={submitInfo} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                        <img className="h-8 mr-3" src={isubmit} alt=""/>
                        <span>SUBMIT</span>
                    </button>
                </div>
                <div className="flex">
                    <img className="h-8 cursor-pointer" onClick={() => onOrCloseQr("open")} src={iqr} alt=""/>
                </div>
            </div>
        </div>
    )
}

export default RaterCredential