import React from 'react'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

import isave from "../data/save-64-c.png";
import icancel from "../data/cancel.png";

import { Query } from '@syncfusion/ej2-data';

const NewService = props => {
    const {
        officeList,
        onSubmit,
        onHandleChange,
        msgVisible,
        newService
    } = props

    const office_fields = { text: 'name', value: 'id' };

    const onFiltering = FilteringEventArgs => {
        let query = new Query();
        query = (FilteringEventArgs.text !== '') ? query.where('name', 'startswith', FilteringEventArgs.text, true) : query;
        FilteringEventArgs.updateData(officeList, query);
    }

    return (
        <div className="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
            <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
            <div className="w-full max-w-lg relative mx-auto my-auto rounded-xl shadow-lg bg-white">
                <div>
                    <div className="text-center p-2 flex-auto justify-center">
                        <h2 className="text-xl font-bold py-3"> ADD NEW OFFICE SERVICE </h2> 
                    </div>

                    {
                        msgVisible ?
                        <div className="flex items-center mx-4 my-2 p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                            <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                            </svg>
                            <span className="sr-only">Info</span>
                            <div className="ml-2">
                                <span className="font-medium">Success alert!</span> New service has beed added.
                            </div>
                        </div>
                        : ''
                    }

                    <div className="flex border-solid mx-4 my-2 border-1 dark:border-neutral-500 px-3 py-1">
                        <DropDownListComponent style={{fontSize: "15px"}} onChange={onHandleChange} name="office" id="office" dataSource={officeList} filtering={onFiltering.bind(this)} filterBarPlaceholder='Search a office' allowFiltering={true} fields={office_fields} placeholder="Select a office" />   
                    </div>

                    <div className="flex border-solid mx-4 my-2 border-1 dark:border-neutral-500 ">               
                        <textarea name="service_name" id="service_name" value={ newService.service_name } rows="3" onChange={onHandleChange} className="block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write the office service here..."></textarea>
                    </div>

                    <div className="flex p-2">
                        <div className="flex-1 justify-start mx-2">
                            <button name="cancel" id="cancel" onClick={() => onSubmit('cancel')} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                                <img className="h-8 mr-3" src={icancel} alt=""/>
                                <span>Cancel</span>
                            </button>
                        </div>
                        <div className="flex justify-end mx-2">
                            <button name="submit" id="submit" onClick={() => onSubmit('submit')} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                                <img className="h-8 mr-3" src={isave} alt=""/>
                                <span>Save</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewService