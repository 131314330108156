import React from 'react'
import QRCode from 'react-qr-code';

const GenerateQr = props => {
    const {
        qRValue,
        onOrCloseQr
    } = props

    return (
        <div className="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
            <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
            <div className="relative mx-auto my-auto rounded-xl shadow-lg bg-white py-6 px-5">
                <div className='flex justify-end -mt-6 -mr-3 mb-1'>
                    <button
                    onClick={() => onOrCloseQr("close")}
                    type="button"
                    className=" box-content rounded-none border-none text-neutral-500 hover:text-neutral-800 hover:no-underline focus:text-neutral-800 focus:opacity-100 focus:shadow-none focus:outline-none dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                    aria-label="Close">
                    <span className="[&>svg]:h-6 [&>svg]:w-6">
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </span>
                    </button>
                </div>
                <div className='flex'>
                    {qRValue && (
                        <QRCode
                            title="SurveyOfficeQr"
                            value={qRValue}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default GenerateQr
