import React from 'react'

import { ccQuestion } from '../data/questions';

const CitizensCharterQ = pros => {
    const {
        lang,
        isQHidden,
        onHandleChange
    } = pros

  return (
    <div>
        <div hidden={isQHidden.cc1}>
            <div className="flex font-bold px-2 bg-gray-500 bg-opacity-30 rounded"> CC1 </div>
            <div className="flex items-center px-2 py-1">
                { ccQuestion[0][lang].question }
            </div>
            <div className="flex items-center px-2 py-1">
                <div className="flex mr-1">
                    <input type="radio" onChange={onHandleChange} value="1" name="cc1" id="cc1_1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                </div>
                <div className="flex">
                    <label htmlFor="cc1_1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 1. { ccQuestion[0][lang].ans1 }</label>       
                </div>      
            </div>
            <div className="flex items-center px-2 py-1">
                <div className="flex mr-1">
                    <input type="radio" onChange={onHandleChange} value="2" name="cc1" id="cc1_2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                </div>
                <div className="flex">
                    <label htmlFor="cc1_2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 2. { ccQuestion[0][lang].ans2 }</label>
                </div>
            </div>
            <div className="flex items-center px-2 py-1">
                <div className="flex mr-1">
                    <input type="radio" onChange={onHandleChange} value="3" name="cc1" id="cc1_3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                </div>
                <div className="flex">
                    <label htmlFor="cc1_3" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 3. { ccQuestion[0][lang].ans3 }</label>
                </div>
            </div>
            <div className="flex items-center px-2 py-1">
                <div className="flex mr-1">
                    <input type="radio" onChange={onHandleChange} value="4" name="cc1" id="cc1_4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                </div>
                <div className="flex">
                    <label htmlFor="cc1_4" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 4. { ccQuestion[0][lang].ans4 }</label>
                </div>
            </div>
        </div>

        <div hidden={isQHidden.cc2}>
            <div className="flex font-bold px-2 bg-gray-500 bg-opacity-30 rounded"> CC2 </div>
            <div className="flex items-center px-2 py-1">
                { ccQuestion[1][lang].question }
            </div>
            <div className="flex items-center px-2 py-1">
                <div className="flex mr-1">
                    <input type="radio" onChange={onHandleChange} value="1" name="cc2" id="cc2_1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                </div>
                <div className="flex">
                    <label htmlFor="cc2_1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 1. { ccQuestion[1][lang].ans1 }</label>     
                </div>      
            </div>
            <div className="flex items-center px-2 py-1">
                <div className="flex mr-1">
                    <input type="radio" onChange={onHandleChange} value="2" name="cc2" id="cc2_2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                </div>
                <div className="flex">
                    <label htmlFor="cc2_2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 2. { ccQuestion[1][lang].ans2 }</label>
                </div>
            </div>
            <div className="flex items-center px-2 py-1">
                <div className="flex mr-1">
                    <input type="radio" onChange={onHandleChange} value="3" name="cc2" id="cc2_3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                </div>
                <div className="flex">
                    <label htmlFor="cc2_3" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 3. { ccQuestion[1][lang].ans3 }</label>
                </div>
            </div>
            <div className="flex items-center px-2 py-1">
                <div className="flex mr-1">
                    <input type="radio" onChange={onHandleChange} value="4" name="cc2" id="cc2_4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                </div>
                <div className="flex">
                    <label htmlFor="cc2_4" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 4. { ccQuestion[1][lang].ans4 }</label>
                </div>
            </div>
            <div className="flex items-center px-2 py-1">
                <div className="flex mr-1">
                    <input type="radio" onChange={onHandleChange} value="5" name="cc2" id="cc2_5" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                </div>
                <div className="flex">
                    <label htmlFor="cc2_5" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 5. { ccQuestion[1][lang].ans5 }</label>
                </div>
            </div>
        </div>

        <div hidden={isQHidden.cc3}>
            <div className="flex font-bold px-2 bg-gray-500 bg-opacity-30 rounded"> CC3 </div>
            <div className="flex items-center px-2 py-1">
                { ccQuestion[2][lang].question }
            </div>
            <div className="flex items-center px-2 py-1">
                <div className="flex mr-1">
                    <input type="radio" onChange={onHandleChange} value="1" name="cc3" id="cc3_1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                </div>
                <div className="flex">
                    <label htmlFor="cc3_1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 1. { ccQuestion[2][lang].ans1 }</label>     
                </div>      
            </div>
            <div className="flex items-center px-2 py-1">
                <div className="flex mr-1">
                    <input type="radio" onChange={onHandleChange} value="2" name="cc3" id="cc3_2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                </div>
                <div className="flex ">
                    <label htmlFor="cc3_2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 2. { ccQuestion[2][lang].ans2 }</label>
                </div>
            </div>
            <div className="flex items-center px-2 py-1">
                <div className="flex mr-1">
                    <input type="radio" onChange={onHandleChange} value="3" name="cc3" id="cc3_3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                </div>
                <div className="flex">
                    <label htmlFor="cc3_3" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 3. { ccQuestion[2][lang].ans3 }</label>
                </div>
            </div>
            <div className="flex items-center px-2 py-1">
                <div className="flex mr-1">
                    <input type="radio" onChange={onHandleChange} value="4" name="cc3" id="cc3_4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                </div>
                <div className="flex">
                    <label htmlFor="cc3_4" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">&nbsp; 4. { ccQuestion[2][lang].ans4 }</label>
                </div>
            </div>
        </div>  
    </div>
  )
}

export default CitizensCharterQ