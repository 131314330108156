import React, { useState, useRef, useEffect } from 'react'

const ThankPage  = () => {
  const Ref = useRef(null);
 
  const [timer, setTimer] = useState("00")
 
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date())
    const seconds = Math.floor((total / 1000) % 60)

    return { total, seconds }
  }
 
  const startTimer = (e) => {
    let { total, seconds } =
      getTimeRemaining(e)
    if (total >= 0) {
      setTimer(
        (seconds > 5 ? seconds : "0" + seconds)
      )
    } else {
      window.location.reload()
    }
  }
 
  const clearTimer = (e) => {
    setTimer("05")

    if (Ref.current) clearInterval(Ref.current)
    const id = setInterval(() => { startTimer(e) }, 1000)
    Ref.current = id
  }
 
  const getDeadTime = () => {
    let deadline = new Date()

    deadline.setSeconds(deadline.getSeconds() + 5)
    return deadline
  }
 
  useEffect(() => {
    clearTimer(getDeadTime())
  }, [])
 
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="p-1 rounded shadow-lg bg-gradient-to-r from-purple-500 via-green-500 to-blue-500">
        <div className="flex flex-col items-center p-10 space-y-2 bg-white">
          <svg xmlns="http://www.w3.org/2000/svg" className="text-green-600 w-28 h-28" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h1 className="text-4xl font-bold font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">Thank You !</h1>
          <p className='text-center text-lg font-semibold'>for participating Customer Satisfaction Survey</p><br></br>
          <span className="text-sm font-medium italic "> *** redirect after *** </span>
          <a className="inline-flex items-center px-4 py-2 text-white bg-indigo-600 border border-indigo-600 rounded rounded-full hover:bg-indigo-700 focus:outline-none focus:ring">
            <span className="text-sm font-medium"> {timer} </span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default ThankPage
