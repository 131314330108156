import React, { useState, useEffect } from "react";
import axios from 'axios'
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Search, Page } from '@syncfusion/ej2-react-grids';

import { Header } from "../components";

const Rating = () => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    })

    const [ratingList, setRatingState] = useState([])
    const [issucess, setSuccess] = useState(false)

    useEffect(() => {
        showRatingList()
    }, [])

    const showRatingList = () => {
        api.get('get/ratings')
        .then(res => {
            if (!issucess) {
                setRatingState(res.data)
                setSuccess(true)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    return (
        <div className="m-2 m-4 p-2 md:p-10 bg-white rounded-3xl">
            <Header category="Rating" title="Rating List" />
            <GridComponent 
            dataSource={ratingList}
            width="auto"
            allowPaging
            allowSorting
            pageSettings={{ pageCount: 5 }}
            
            >
                <ColumnsDirective>
                    <ColumnDirective field='office.name' width='280' headerText='Office'/>
                    <ColumnDirective field='rater.rater_name' headerText='Rater'/>
                    <ColumnDirective field='rating.cc1' headerText='CC1'/>
                    <ColumnDirective field='rating.cc2' headerText='CC2'/>
                    <ColumnDirective field='rating.cc3' headerText='CC3'/>
                    <ColumnDirective field='rating.q1' headerText='SQD0'/>
                    <ColumnDirective field='rating.q2' headerText='SQD1'/>
                    <ColumnDirective field='rating.q3' headerText='SQD2'/>
                    <ColumnDirective field='rating.q4' headerText='SQD3'/>
                    <ColumnDirective field='rating.q5' headerText='SQD4'/>
                    <ColumnDirective field='rating.q6' headerText='SQD5'/>
                    <ColumnDirective field='rating.q7' headerText='SQD6'/>
                    <ColumnDirective field='rating.q8' headerText='SQD7'/>
                    <ColumnDirective field='rating.q9' headerText='SQD8'/>
                </ColumnsDirective>
                <Inject services={[Search, Page]} />

            </GridComponent>
        </div>
    );
};

export default Rating;