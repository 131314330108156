import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';

import isubmit from "../data/submit.png";

const OnSitePage = () => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    })

    const navigate = useNavigate()
    const [campusList, setCampusState] = useState([])
    const [officeList, setOfficeState] = useState([])
    const [msgVisible, setMsgVisible] = useState(false)
    const campus_fields = { text: 'campus_name', value: 'id' };
    const office_fields = { text: 'name', value: 'id' }
    const [dataEntry, setDataEntry] = useState( {
        office: '',
        campus_id: ''
    })

    useEffect(() => {
        showCampusList()
    }, [])

    const showCampusList = () => {
        api.get('get/campus')
        .then(res => {
            setCampusState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const onHandleChange = (e) => {
        const {name, value} = e.target
        
        setDataEntry({...dataEntry, [name]: value})

        if (name === 'campus_id') {
            let campus = campusList.find((item) => item.id === value)
            setOfficeState([])
            setOfficeState(campus.offices)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
     
        if (dataEntry.campus_id.length === 0 || dataEntry.office.length === 0) {
            setMsgVisible(true)
        } else {
            let newUrl = "/survey?onsite=yes&c="+ dataEntry.campus_id +"&o=" + dataEntry.office
            setMsgVisible(false)
            navigate(newUrl, { replace: true })
        }
    }

    const onFiltering = FilteringEventArgs => {
        let query = new Query();
        query = (FilteringEventArgs.text !== '') ? query.where('name', 'startswith', FilteringEventArgs.text, true) : query;
        FilteringEventArgs.updateData(officeList, query);
    }

    return (
        <div className="flex items-center justify-center h-screen dark:bg-main-dark-bg">
            <div className="w-full max-w-xl bg-white border border-gray-200 rounded-lg shadow-md p-6 mt-3">
                {
                    msgVisible &&
                    <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                        <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                        </svg>
                        <span className="sr-only">Info</span>
                        <div className="ml-2">
                            <span className="font-medium">Message alert!</span> All fields are required, Please check and try again.
                        </div>
                    </div>
                }

                <div className="flex items-center justify-center mb-5 -mt-1">
                    <div className=" flex w-full max-w-xl justify-center">
                        <label className="inline-flex items-center border border-gray-200 rounded-lg shadow-md backdrop-blur-xl bg-white/30 px-4 py-1">
                            <h1 className="mb-1 text-center text-lg sm:text-2xl font-extrabold text-gray-900 dark:text-white"><span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">ONSITE LOGIN</span></h1>
                        </label>
                    </div>
                </div>

                <div className="flex mt-2 border-solid border-1 dark:border-neutral-500 px-3 py-1">
                    <DropDownListComponent style={{fontSize: "15px"}} name="campus_id" id="campus_id" onChange={onHandleChange} dataSource={campusList} filterBarPlaceholder='Search a campus' allowFiltering={true} fields={campus_fields} placeholder="Select a campus" />   
                </div>
                <div className="flex mt-2 border-solid border-1 dark:border-neutral-500 px-3 py-1">
                    <DropDownListComponent style={{fontSize: "15px"}} name="office" id="office" onChange={onHandleChange} dataSource={officeList} filtering={onFiltering.bind(this)} filterBarPlaceholder='Search a office' allowFiltering={true} fields={office_fields} placeholder="Select a office" />   
                </div>
                <div className="flex justify-center mt-5">
                    <button name="submit" id="submit" onClick={onSubmit} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                        <img className="h-8 mr-3" src={isubmit} alt=""/>
                        <span>SUBMIT</span>
                    </button>
                </div>
            </div>
        </div>    
    )
}

export default OnSitePage