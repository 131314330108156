import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from 'axios'
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Search, Page } from '@syncfusion/ej2-react-grids';
import iadd from "../data/add.png";

import { Header } from "../components";
import NewService from "./NewService";

const ServicePage = () => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    })
    
    const [cookies] = useCookies()
    const [serviceList, setServiceState] = useState([])
    const [officeList, setOfficeState] = useState([])
    const [isAddNewService, setIsAddNewService] = useState(false)
    const [newService, setnewService] = useState({
        office: '',
        service_name: '',
        campus_id: cookies['__info'].campus_id
    })
    const [msgVisible, setMsgVisible] = useState(false)

    useEffect(() => {
        showAllServicesList()
        showOfficeList()
    }, [])

    const showAllServicesList = () => {
        api.get('get/all-services/' + cookies['__info'].campus_id)
        .then(res => {
            setServiceState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const showOfficeList = () => {
        api.get('get/office/' + cookies['__info'].campus_id)
        .then(res => {
            setOfficeState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const checkOfficeService = (id, name) => {
        api.get('get/check-office-service/' + id + "/" + name)
        .then(res => {
            if (res.data === 0) {
                saveNewService(newService)
                newService.service_name = ''
            } else {
                alert("Service already exist!")
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    const onAddNewService = () => {
        setIsAddNewService(true)
    }

    const onHandleChange = (e) => {
        const {name, value} = e.target

        setnewService({...newService, [name]: value})
        
    }

    const onSubmit = (whatBtn) => {
        if (whatBtn === 'submit') {
            if (newService.office.length === 0 || newService.service_name.length === 0) {
                alert("Required fields, Please try agan!")
            } else {
                checkOfficeService(newService.office, newService.service_name.toUpperCase())
            }
        } else {
            setIsAddNewService(false)
            setMsgVisible(false)
        }
    }

    const saveNewService = async (params) => {
        let res = await api.post('post/add-new-service', {params})

        if (res.status === 200) {
            if (res.data === 'success') {
                setMsgVisible(true)
                showAllServicesList()
            } else {
                alert("Attention! Failed to save, Please try again.")
            }
        }
    }
    
    return (
        <div className="m-2 m-4 p-2 md:p-10 bg-white rounded-3xl">
            <Header category="Pages" title="Services" />
            
            { isAddNewService ? <NewService onSubmit={onSubmit} officeList={officeList} onHandleChange={onHandleChange} msgVisible={msgVisible} newService={newService} /> : '' }
            
            <div className="flex mb-5">
                <div className="flex-1">
                    <div className="relative w-full">
                        <input type="search" id="search-dropdown" className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" placeholder="Search office or service....." required />
                        <div className="flex justify-end">
                            <button type="submit" className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                                <span className="sr-only">Search</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex-1">
                    <div className="flex justify-end">
                    <button onClick={ onAddNewService } className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                        <img className="h-8 mr-3" src={iadd} alt=""/>
                        <span>Service</span>
                    </button>
                    </div>
                </div>
            </div>

            <GridComponent 
            dataSource={serviceList}
            width="auto"
            allowPaging
            allowSorting
            pageSettings={{ pageCount: 5 }}
            >
                <ColumnsDirective>
                    <ColumnDirective field='office.name' headerText='Name of Office' />
                    <ColumnDirective field='name' headerText='Name of Service' />
                </ColumnsDirective>
                <Inject services={[Search, Page]} />

            </GridComponent>
        </div>
    )
}

export default ServicePage