import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

import axios from 'axios'

import CitizensCharterQ from "./CitizensCharterQ";
import SQDTemplate from "./SQDTemplate";
import RaterInfo from "./RaterInfo";
import SurveyHeader from "./SurveyHeader";
import RaterCredential from "./RaterCredential";
import ThankPage from "./ThankPage";
import IntroPage from "./IntroPage";
import GenerateQr from "./GenerateQr";

import inext from "../data/arrow-right-c.png";
import iproceed from "../data/proceed.png";
import prev from "../data/arrow-left-c.png";
import { RaterInfoText, introText } from '../data/list';

const Survey = () => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    })

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    
    const dateValue = new Date()
    
    const [survey_where, setSurveyWhere] = useState("online");
    const [lang, setLang] = useState("en");
    const [langName, setLangName] = useState("English");
    const [servicesList, setServicesState] = useState([])
    const [campusList, setCampusState] = useState([])
    const [officeList, setOfficeState] = useState([])
    const [raterList, setRaterState] = useState([])
    const [isRaterCredDone, setIsRaterCredDone] = useState(false)
    const [msgVisible, setMsgVisible] = useState(false)
    const [questionError, setQuestionError] = useState(false)
    const [isSubmitShow, setIsSubmitShow] = useState(false)
    const [isPreLoading, setPreLoading] = useState(false);
    const [isThankYou, setThankYou] = useState(false);
    const [checked, setChecked] = useState(true);
    const [isIntroHide, setIsIntroHide] = useState(true);
    const [qRValue, setQrValue] = useState(false);
    const [ProvName, setProvName] = useState('')
    const [MunName, setMunName] = useState('')
    
    const [dataEntry, setDataEntry] = useState( {
        rating_date: dateValue,
        rater: '',
        office: '',
        sex: '',
        age: '',
        service_avail: '',
        barangay_id: '',
        campus_id: '',
        sqd0: '',
        sqd1: '',
        sqd2: '',
        sqd3: '',
        sqd4: '',
        sqd5: '',
        sqd6: '',
        sqd7: '',
        sqd8: '',
        suggestion: '',
        email_address: '',
        cc1: '',
        cc2: '',
        cc3: '',
        type_of_survey: survey_where,
        language: langName
    })
    
    const [isQHidden, setIsQHidden] = useState({
        sqd0: true,
        sqd1: true,
        sqd2: true,
        sqd3: true,
        sqd4: true,
        sqd5: true,
        sqd6: true,
        sqd7: true,
        sqd8: true,
        cc1: true,
        cc2: true,
        cc3: true,
        cccont: true,
        ctr: 0
    })

    useEffect(() => {
        showCampusList()
        showRaterList()
    }, [])

    useEffect(() => {
        if (checked) {
            setLang('en')
            setLangName('English')
            dataEntry.language = "English"
        }
        else {
            setLang('fil')
            setLangName('Tagalog') 
            dataEntry.language = "Tagalog"  
        }
    }, [checked, dataEntry])

    const showCampusList = () => {
        api.get('get/campus')
        .then(res => {
            setCampusState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const showRaterList = () => {
        api.get('get/rater')
        .then(res => {
            setRaterState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const showServicesList = (id) => {
        api.get('get/services/' + id)
        .then(res => {
            setServicesState(res.data)
            
            if (res.data.length === 0) {
                alert("This office has no available services list.")
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    const updateValueFromState = (val, mun, prov) => {
        setDataEntry({...dataEntry, barangay_id: val})
        setProvName(prov)
        setMunName(mun)
    }

    const selectedServicesValue = (val) => {
        setDataEntry({...dataEntry, service_avail: val})
    }

    const onHandleChange = (e) => {
        const {name, value} = e.target
        
        setDataEntry({...dataEntry, [name]: value})

        if (name === 'office') {
            showServicesList(value)    
        }

        if (name === 'campus_id') {
            let campus = campusList.find((item) => item.id === value)
            setOfficeState([])
            setOfficeState(campus.offices)
        }

        if (name === 'sqd8') setIsSubmitShow(true)
    }

    const onSubmitData = (e) => {
        e.preventDefault()

        setIsSubmitShow(false)
        setPreLoading(true)
        saveNewRating(dataEntry)
    }

    const saveNewRating = async (params) => {
        let res = await api.post('post/save-rating', {params})

        if (res.status === 200) {
            if (res.data === 'success') {
                setThankYou(true)
            } else {
                alert("Attention! Failed to save, Please try again.")
            }
        }
    }

    const movePrev = () => {
        let ctr = parseInt(isQHidden.ctr) - parseInt(1)
        
        if (ctr === 0) {
            setIsQHidden({...isQHidden, cc1: true, ctr: ctr, cccont: true})        
        } else if (ctr === 1) {
            setIsQHidden({...isQHidden, cc2: true, ctr: ctr, cc1: false}) 
        } else if (ctr === 2) {
            setIsQHidden({...isQHidden, cc3: true, ctr: ctr, cc2: false}) 
        } else if (ctr === 3) {
            setIsQHidden({...isQHidden, sqd0: true, ctr: ctr, cc3: false}) 
        } else if (ctr === 4) {
            setIsQHidden({...isQHidden, sqd1: true, ctr: ctr, sqd0: false}) 
        } else if (ctr === 5) {
            setIsQHidden({...isQHidden, sqd2: true, ctr: ctr, sqd1: false}) 
        } else if (ctr === 6) {
            setIsQHidden({...isQHidden, sqd3: true, ctr: ctr, sqd2: false}) 
        } else if (ctr === 7) {
            setIsQHidden({...isQHidden, sqd4: true, ctr: ctr, sqd3: false}) 
        } else if (ctr === 8) {
            setIsQHidden({...isQHidden, sqd5: true, ctr: ctr, sqd4: false}) 
        } else if (ctr === 9) {
            setIsQHidden({...isQHidden, sqd6: true, ctr: ctr, sqd5: false}) 
        } else if (ctr === 10) {
            setIsQHidden({...isQHidden, sqd7: true, ctr: ctr, sqd6: false}) 
        } else if (ctr === 11) {
            setIsQHidden({...isQHidden, sqd8: true, ctr: ctr, sqd7: false}) 
        }
    }

    const moveNext = () => {
        let ctr = parseInt(isQHidden.ctr) + parseInt(1)
        
        if (ctr === 1) {
            setIsQHidden({...isQHidden, cc1: false, ctr: ctr}) 
        } else if (ctr === 2) {
            if (checkSelection(dataEntry.cc1) === 1) setQuestionError(true)
            else setIsQHidden({...isQHidden, cc2: false, ctr: ctr, cc1: true})   
        } else if (ctr === 3) {
            if (checkSelection(dataEntry.cc2) === 1) setQuestionError(true)
            else setIsQHidden({...isQHidden, cc3: false, ctr: ctr, cc2: true}) 
        } else if (ctr === 4) {
            if (checkSelection(dataEntry.cc3) === 1) setQuestionError(true)
            else setIsQHidden({...isQHidden, sqd0: false, ctr: ctr, cc3: true}) 
        } else if (ctr === 5) {
            if (checkSelection(dataEntry.sqd0) === 1) setQuestionError(true)
            else  setIsQHidden({...isQHidden, sqd1: false, ctr: ctr, sqd0: true}) 
        } else if (ctr === 6) {
            if (checkSelection(dataEntry.sqd1) === 1) setQuestionError(true)
            else setIsQHidden({...isQHidden, sqd2: false, ctr: ctr, sqd1: true}) 
        } else if (ctr === 7) {
            if (checkSelection(dataEntry.sqd2) === 1) setQuestionError(true)
            else setIsQHidden({...isQHidden, sqd3: false, ctr: ctr, sqd2: true}) 
        } else if (ctr === 8) {
            if (checkSelection(dataEntry.sqd3) === 1) setQuestionError(true)
            else setIsQHidden({...isQHidden, sqd4: false, ctr: ctr, sqd3: true}) 
        } else if (ctr === 9) {
            if (checkSelection(dataEntry.sqd4) === 1) setQuestionError(true)
            else setIsQHidden({...isQHidden, sqd5: false, ctr: ctr, sqd4: true}) 
        } else if (ctr === 10) {
            if (checkSelection(dataEntry.sqd5) === 1) setQuestionError(true)
            else setIsQHidden({...isQHidden, sqd6: false, ctr: ctr, sqd5: true}) 
        } else if (ctr === 11) {
            if (checkSelection(dataEntry.sqd6) === 1) setQuestionError(true)
            else setIsQHidden({...isQHidden, sqd7: false, ctr: ctr, sqd6: true}) 
        } else if (ctr === 12) {
            if (checkSelection(dataEntry.sqd7) === 1) setQuestionError(true)
            else setIsQHidden({...isQHidden, sqd8: false, ctr: ctr, sqd7: true}) 
        }
    }
    
    const checkSelection = (data) => {
        if (data.length === 0) return 1
        else{
            setQuestionError(false)
            return 0 
        } 
    }

    const moveProceed = () => {
        setIsQHidden({...isQHidden, cccont: false, cc1: false, ctr: 1})
    }

    const submitInfo = (e) => {
        e.preventDefault()
     
        if (dataEntry.rater.length === 0 || dataEntry.office.length === 0 ||
            dataEntry.barangay_id.length === 0 || dataEntry.sex.length === 0 || 
            dataEntry.service_avail.length === 0 || dataEntry.age.length === 0) {
            setMsgVisible(true)
        } else {
            setMsgVisible(false)
            setIsRaterCredDone(true)   
        }
    }
    
    const onHideIntro = () => {
        setIsIntroHide(false)

        let isOnesite = queryParams.get('onsite');
        let c = queryParams.get('c');
        let o = queryParams.get('o');
        
        if (isOnesite) {
            if (isOnesite.toLowerCase() === "yes") {
                dataEntry.campus_id = parseInt(c)

                let campus = campusList.find((item) => item.id === parseInt(c))
                setOfficeState([])
                setOfficeState(campus.offices)
                showServicesList(o)

                dataEntry.office = parseInt(o)
                setSurveyWhere('onsite')
                dataEntry.type_of_survey = "onsite"
            }
        }
    }

    const onChangeIntro = () => {
        setChecked((state) => !state)
    }

    const onOrCloseQr = (mode) => {
        if (mode === "open") {
            if (survey_where === "online") 
                setQrValue(process.env.REACT_APP_URL + location.pathname)
            else
                setQrValue(process.env.REACT_APP_URL + location.pathname + location.search)
        } else if(mode === "close") {
            setQrValue(false)
        }
    }

    return (
        <div>
            {
                !isThankYou ?
                    <div className="flex-none">
                        <div className="flex items-center justify-center dark:bg-main-dark-bg mt-2 sm:mt-4">
                            <div className=" flex w-full max-w-xl justify-center">
                                <label className="inline-flex items-center border border-gray-200 rounded-lg shadow-md backdrop-blur-xl bg-white/30 px-4 py-1">
                                    <h1 className="mb-1 text-center text-lg sm:text-2xl font-extrabold text-gray-900 dark:text-white"><span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">Client Satisfaction Survey (CSS) </span></h1>
                                </label>
                            </div>
                        </div>
                    
                        { isIntroHide ? <IntroPage onHideIntro={onHideIntro} onChangeIntro={onChangeIntro} introText={introText[0][lang]} lang={lang} langName={langName} checked={checked} /> : '' }
                        
                        { qRValue && <GenerateQr qRValue={qRValue} onOrCloseQr={onOrCloseQr} /> }

                        <div hidden={isRaterCredDone === false ? false : true}>
                            <RaterCredential 
                            onHandleChange={onHandleChange} 
                            servicesList={servicesList} 
                            submitInfo={submitInfo} 
                            updateValueFromState={updateValueFromState}
                            selectedServicesValue={selectedServicesValue}
                            campusList={campusList}
                            officeList={officeList}
                            raterList={raterList}
                            msgVisible={msgVisible}
                            dataEntry={dataEntry}
                            survey_where={survey_where}
                            onOrCloseQr={onOrCloseQr}
                            />
                        </div>
            
                        <div hidden={isRaterCredDone === false ? true : false}>
                            <SurveyHeader />
            
                            { isRaterCredDone ? 
                            <RaterInfo 
                            dataEntry={dataEntry} 
                            raterList={raterList}
                            officeList={officeList}
                            servicesList={servicesList}
                            RaterInfoText={RaterInfoText[0][lang]}
                            ProvName={ProvName}
                            MunName={MunName}
                            /> 
                            : ''
                            }
                            
                            <div hidden={ isQHidden.cccont === false ? true : false }>
                                <div className="flex items-center justify-center dark:bg-main-dark-bg">
                                    <div className="w-[1020px] sm:w-100 bg-white border border-gray-200 rounded-lg shadow-md p-6 mt-1">
                                        <p className="ms-2 text-sm sm:text-base font-bold text-gray-900 dark:text-gray-300 mb-2">
                                            { RaterInfoText[0][lang].intro }:
                                        </p>
                                        <p className="ms-2 text-sm sm:text-base text-gray-700 dark:text-gray-300">
                                            { RaterInfoText[0][lang].cc_guide }
                                        </p>
            
                                        <p className="ms-2 text-sm sm:text-base text-gray-700 dark:text-gray-300 mt-4">
                                            { RaterInfoText[0][lang].sqd_guide }
                                        </p>
            
                                        <div className="flex justify-center mt-8">
                                            <button name="proceed" id="proceed" onClick={moveProceed} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                                                <img className="h-8 mr-3" src={iproceed} alt=""/>
                                                <span>Proceed</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
            
                            <div hidden={ isQHidden.cccont === true ? true : false }>
                                <div className="flex items-center justify-center dark:bg-main-dark-bg">
                                    <div className="w-[1020px] sm:w-100 bg-white border border-gray-200 rounded-lg shadow-md p-6 mt-1">
                                        {
                                            questionError ?
                                            <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-100 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                                                </svg>
                                                <span className="sr-only">Info</span>
                                                <div className="ml-2">
                                                    <span className="font-bold">Message alert!</span> Please select one!.
                                                </div>
                                            </div>
                                            : ''
                                        }
                                        <CitizensCharterQ isQHidden={isQHidden} lang={lang} onHandleChange={onHandleChange} />
                                        <SQDTemplate 
                                        isQHidden={isQHidden} 
                                        survey_where={survey_where} 
                                        lang={lang} 
                                        isSubmitShow={isSubmitShow} 
                                        isPreLoading={isPreLoading}
                                        onHandleChange={onHandleChange} 
                                        onSubmitData={onSubmitData} />
                                    </div>
                                </div>
                            </div>
                            
                            {
                                !isPreLoading ?
                                <div hidden={ isQHidden.cccont ? true : false }>
                                    <div className="flex items-center justify-center dark:bg-main-dark-bg py-1">
                                        <div className="w-[1020px] sm:w-100 bg-white border border-gray-200 rounded-lg shadow-md py-2 px-2 mb-2">
                                            <div className="flex items-center">
                                                <div className="flex-1" hidden={ isQHidden.ctr === 0 ? true : false }>
                                                    <div className="flex justify-start">
                                                        <button name="prev" id="prev" onClick={movePrev} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                                                            <img className="h-8 mr-3" src={prev} alt=""/>
                                                            <span>Prev</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="flex-1" hidden={ isQHidden.ctr === 12 ? true : false }>
                                                    <div className="flex justify-end">
                                                        <button name="next" id="next" onClick={moveNext} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                                                            <span>Next</span>
                                                            <img className="h-8 ml-3" src={inext} alt=""/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                : 
                <ThankPage />
            }

        </div>
    )
}

export default Survey