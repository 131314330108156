import React, { useState, useEffect } from "react";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';

import axios from 'axios'

const GeoLocation = props => {
    const {
        updateValueFromState
    } = props

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    })
    
    const region_fields = { text: 'name', value: 'id' }
    const province_fields = { text: 'name', value: 'id' }
    const municipality_fields = { text: 'name', value: 'id' }
    const barangay_fields = { text: 'name', value: 'id' }
    const [regionList, setRegionState] = useState([])
    const [issucess1, setSuccess3] = useState(false)
    const [provinceList, setProvinceState] = useState([])
    const [munilipalityList, setMunicipalityState] = useState([])
    const [barangayList, setBarangayState] = useState([])
    const [barangayId, setBarangayId] = useState(null)
    const [ProvName, setProvName] = useState('')
    const [MunName, setMunName] = useState('')

    useEffect(() => {
        showRegion()
    }, [])

    const showRegion = () => {
        api.get('get/get-region')
        .then(res => {
            if (!issucess1) {
                setRegionState(res.data)
                setSuccess3(true)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    const showProvince = (id) => {
        api.get('get/get-province/'+id)
        .then(res => {
            setProvinceState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const showMunicpality = (id) => {
        api.get('get/get-municipality/'+id)
        .then(res => {
            setMunicipalityState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const showBarangay = (id) => {
        api.get('get/get-barangay/'+id)
        .then(res => {
            setBarangayState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const onFilteringRegion = FilteringEventArgs => {
        let query = new Query();
        query = (FilteringEventArgs.text !== '') ? query.where('name', 'startswith', FilteringEventArgs.text, true) : query;
        FilteringEventArgs.updateData(regionList, query);
    }

    const onFilteringProvince = FilteringEventArgs => {
        let query = new Query();
        query = (FilteringEventArgs.text !== '') ? query.where('name', 'startswith', FilteringEventArgs.text, true) : query;
        FilteringEventArgs.updateData(provinceList, query);
    }

    const onFilteringMunicipality = FilteringEventArgs => {
        let query = new Query();
        query = (FilteringEventArgs.text !== '') ? query.where('name', 'startswith', FilteringEventArgs.text, true) : query;
        FilteringEventArgs.updateData(munilipalityList, query);
    }

    const onFilteringBarangay = FilteringEventArgs => {
        let query = new Query();
        query = (FilteringEventArgs.text !== '') ? query.where('name', 'startswith', FilteringEventArgs.text, true) : query;
        FilteringEventArgs.updateData(barangayList, query);
    }

    const onHandleChange = (e) => {
        const {name, value} = e.target
     
        if (name === 'region') {
            setProvinceState([])
            setMunicipalityState([])
            setBarangayState([])
            showProvince(value)
        } else if(name === 'province') {
            setMunicipalityState([])
            setBarangayState([])
            showMunicpality(value)
            setProvName(e.target['itemData'].name)
        } else if(name === 'municipality') {
            setBarangayState([])
            showBarangay(value)
            setMunName(e.target['itemData'].name)
        } else if(name === 'barangay') {
            setBarangayId({...barangayId, [name]: value})
            updateValueFromState(value, MunName, ProvName)
        } 
    }

    return (
        <div>
            <div className="flex mt-2 border-solid border-1 dark:border-neutral-500 px-3 py-1">
                <div className="flex-1">
                    <DropDownListComponent style={{fontSize: "15px"}} name="region" id="region" onChange={onHandleChange} dataSource={regionList} filtering={onFilteringRegion.bind(this)} filterBarPlaceholder='Search a region' allowFiltering={true} fields={region_fields} placeholder="Select a region" />   
                </div>
            </div>
            <div className="flex mt-2 border-solid border-1 dark:border-neutral-500 px-3 py-1">
                <div className="flex-1">
                    <DropDownListComponent style={{fontSize: "15px"}} name="province" id="province" onChange={onHandleChange} dataSource={provinceList} filtering={onFilteringProvince.bind(this)} filterBarPlaceholder='Search a province' allowFiltering={true} fields={province_fields} placeholder="Select a province" />   
                </div>
            </div>
            <div className="flex mt-2 border-solid border-1 dark:border-neutral-500 px-3 py-1">
                <div className="flex-1">
                    <DropDownListComponent style={{fontSize: "15px"}} name="municipality" id="municipality" onChange={onHandleChange} dataSource={munilipalityList} filtering={onFilteringMunicipality.bind(this)} filterBarPlaceholder='Search a municipality' allowFiltering={true} fields={municipality_fields} placeholder="Select a municipality" />   
                </div>
            </div>
            <div className="flex mt-2 border-solid border-1 dark:border-neutral-500 px-3 py-1">
                <div className="flex-1">
                    <DropDownListComponent style={{fontSize: "15px"}} name="barangay" id="barangay" onChange={onHandleChange} dataSource={barangayList} filtering={onFilteringBarangay.bind(this)} filterBarPlaceholder='Search a barangay' allowFiltering={true} fields={barangay_fields} placeholder="Select a barangay" />   
                </div>
            </div>
        </div>
    )
}

export default GeoLocation