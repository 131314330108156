import React from 'react'
import istart from "../data/start.png";
const IntroPage = props => {

    const {
        onHideIntro,
        onChangeIntro,
        langName,
        checked,
        introText
    } = props

    return (
        <div className="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
            <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
            <div className="w-full max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-white ">
                <div className=" flex justify-end">
                    <label className="inline-flex items-center cursor-pointer">
                        <input type="checkbox" name="lang" value="" className="sr-only peer" defaultChecked={checked} onChange={onChangeIntro} />
                        <div className="relative w-11 h-6 bg-green-600 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 ml-1">{langName}</span>
                    </label>
                </div>
            
                <div className="">
                    <div className="text-center p-5 flex-auto justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-11 h-6 -m-1 flex items-center text-blue-500 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        
                        <h2 className="text-xl font-bold py-4">{ introText.title }</h2>
                        <p className="text-sm text-gray-500 sm:px-8">{ introText.desc }</p>    
                    </div>
                    <div className="p-2 text-center space-x-4 md:block">
                        {/* <button onClick={onHideIntro} className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100">
                            { introText.button }
                        </button> */}
                        <button name="intro_submit" id="intro_submit" onClick={onHideIntro} className="inline-flex items-center mb-2 md:mb-0 bg-white px-3 py-1 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100">
                            <img className="h-8 mr-3" src={istart} alt=""/>
                            <span>{ introText.button }</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntroPage